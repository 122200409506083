import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_6_month from '../../../../../../../common/src/assets/image/example_6_month.png';
import template_options_6_months from '../../../../../../../common/src/assets/image/template_options_6_months.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The 6-Months/Page template style generates 6 months to a single page.
        Dates can be color-coded based on the first event or task. Events and
        tasks will also be listed to the right of each month.
      </p>
      <p>
        <Image alt="" src={example_6_month} style={{ width: 980 }} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The 6-Months/Page template provides the following options when creating
        a calendar...
      </p>
      <Image alt="" src={template_options_6_months} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start month</strong>
            </td>
            <td>The first of the 6 months to be printed.</td>
          </tr>
          <tr>
            <td>
              <strong>First day of week</strong>
            </td>
            <td>
              The first day of the week will be the left-most day column in each
              month.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Color dates</strong>
            </td>
            <td>
              <span>
                When checked, each date will be color-coded to match calendar
                associated with the first event or task occurring on that date.
                Note, you can hover over a date to see all the events and tasks
                it contains.;
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Month-6-Months-Page"
      commentsId="commentsplus_post_169_489"
      title="6 Months/Page Template"
      description="Generate 6 months to a single page. Dates can be color-coded based on the first event or task. Events and tasks will also be listed to the right of each month."
      keywords="six month template, 6 month template, half year template"
      content={content}
    />
  );
};

export default Documentation;
